/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useEffect, useRef, useCallback, useContext } from 'react';
import { useRouter } from 'next/router';
import cx from 'classnames';
import { Label } from '@/features/i18n';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import { routePaths } from '../../constants/routePaths';
import styles from './myAccount.module.scss';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { setCookie } from '@/utils/cookie';
import { sessionStorage } from '@/utils/sessionStorage';
import { useStreamLineSignIn } from '@/hooks/useStreamLineSignIn';
import { localStorage } from '@/utils/localStorage';
import {
  Actionable,
  Button,
  Hidden,
  Icon,
  type IconProps,
  Text,
  type TextProps,
  View,
} from '@az/starc-ui';
import { BillPayCircle } from '@az/starc-ui';
import { SignInFlowTypeContext } from '../SignInFlowTypeContext';
import { useLabels } from '@/hooks/useLabels';
import {
  Car,
  Avatar,
  Located,
  OrderHistoryFilled,
  AZRewardsIconFilled,
  MyWarrantiesFilled,
  NavigationHome,
  RepairShop,
} from './Icons/Icons';
import { SIGNED_IN_USER_WITH_REWARDS_USER_TYPE } from '@/features/header';
import { useMediaQuery } from '@/hooks/useMediaQuery';

type MenuItemProps = {
  title?: string;
  description?: string;
  icon?: IconProps['svg'];
  type?: string;
  href?: string;
  onClick?: () => void;
  boldTitle?: boolean;
  usOnly?: boolean;
  hideMenuItem?: boolean;
};

export function MenuItem({
  title,
  description,
  icon = BillPayCircle,
  type = 'topNav',
  href,
  onClick,
  boldTitle,
  usOnly,
  hideMenuItem,
}: MenuItemProps) {
  const locale = useLocale();
  const isUS = locale === countryCodes.enUS;
  const router = useRouter();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  if ((usOnly && !isUS) || hideMenuItem) {
    return <></>;
  }

  const getStyle = (type?: string) => {
    // define non-default types with alternative styling for use with other components, ex: account page
    switch (type) {
      default: // topnav & stickynav
        return {
          iconProps: {
            size: (24 / 4) as IconProps['size'],
            viewItemPaddingRight: isMobile ? 3 : 10 / 5,
          },
          titleProps: {
            variant: 'large-body' as TextProps['variant'],
            viewLineHeight: isMobile ? '20px' : '26px',
            fontSize: isMobile ? '14px' : '18px',
          },
          descriptionProps: {
            variant: 'small-body' as TextProps['variant'],
            className: styles.starcTextVariantOverrideSmallBody,
            viewItemGapBefore: 2 / 4,
            viewLineHeight: isMobile ? '20px' : '22px',
            fontSize: isMobile ? '12px' : '',
          },
        };
    }
  };

  const { iconProps, titleProps, descriptionProps } = getStyle(type);

  const handleActionableOnclick = () => {
    if (href && onClick) {
      onClick();
      router.push(href);
    }
  };

  return (
    <Actionable
      type="button"
      className={styles.anchorTag}
      fullWidth
      attributes={{ 'data-testid': `${title}` }}
      onClick={handleActionableOnclick}
    >
      <View direction="row" padding={[1, 4]}>
        <View.Item>
          <Icon svg={icon} {...iconProps} />
        </View.Item>
        <View.Item gapBefore={iconProps?.viewItemPaddingRight} grow>
          <View direction="column">
            <View.Item>
              <View direction="row">
                <Text
                  {...titleProps}
                  attributes={{
                    style: {
                      fontWeight: boldTitle ? '700' : '400',
                      lineHeight: boldTitle && !isMobile ? '24px' : titleProps?.viewLineHeight,
                      fontSize: boldTitle && !isMobile ? '18px' : titleProps?.fontSize,
                    },
                  }}
                >
                  {title}
                </Text>
              </View>
            </View.Item>
            <View.Item gapBefore={descriptionProps?.viewItemGapBefore} />
            <View.Item>
              <View direction="row">
                <Text
                  {...descriptionProps}
                  attributes={{ style: { lineHeight: descriptionProps?.viewLineHeight } }}
                >
                  {description}
                </Text>
              </View>
            </View.Item>
          </View>
        </View.Item>
      </View>
    </Actionable>
  );
}

type MenuItemsProps = {
  rewardsBalance: string;
  showErr?: boolean;
  userAuthenticated: any;
  userFirstName?: string;
  userType?: number;
  closeModal: () => void;
  closeModalAndSignOut: () => void;
  refocusMyAcctBtn: () => void;
};

export const MenuItems = ({
  rewardsBalance,
  showErr,
  userAuthenticated,
  userFirstName,
  userType,
  closeModal,
  closeModalAndSignOut,
  refocusMyAcctBtn,
}: MenuItemsProps) => {
  const starcBtnRef = useRef<HTMLButtonElement>(null);
  const { setFlowTypeFn } = useContext(SignInFlowTypeContext);
  const router = useRouter();
  const labels = useLabels();
  const isSignInV2Enabled = useStreamLineSignIn();
  const locale = useLocale();

  const createAccountV2Route = isSignInV2Enabled ? routePaths.signIn : routePaths.create;
  const isUS = locale === countryCodes.enUS;

  const { data: storeData } = useStoreDetailsData();
  const isBopusEnabled = storeData?.bopusEnabled ?? false;
  const isBopusMexicoStoreEnabled = locale === countryCodes.mx && isBopusEnabled;

  useEffect(() => {
    if (!userAuthenticated) {
      setCookie('loginInteractionMethod', 'Header');
    }
  }, [userAuthenticated]);

  const handleCloseModal = useCallback(() => {
    closeModal();
    refocusMyAcctBtn();
  }, [closeModal, refocusMyAcctBtn]);

  const handleSignInClick = useCallback(() => {
    if (router.pathname === routePaths.cart) {
      sessionStorage.setItem('fromSignin', router.pathname);
    }
    localStorage.setItem('shouldDisplayLoginMsgOnReload', 'true');
    localStorage.setItem('signInModalSelectedTab', 'signIn');
    handleCloseModal();
  }, [handleCloseModal, router]);

  const handleV2SignInClick = useCallback(() => {
    handleSignInClick();
    setFlowTypeFn('login');
    router.push(routePaths.signIn);
  }, [handleSignInClick, router, setFlowTypeFn]);

  const handleCloseModalAndSignOut = () => {
    closeModalAndSignOut();
    refocusMyAcctBtn();
  };

  const formatName = (name: string) => {
    if (name === undefined) {
      return;
    }

    const names = name.toLowerCase().split(' ');
    let formattedName = '';
    for (let i = 0; i < names.length; i++) {
      if (i > 0) {
        formattedName += ' ';
      }
      formattedName += names[i].charAt(0).toUpperCase() + names[i].slice(1);
    }
    return formattedName;
  };

  const signInBlock = (
    <View justify="center" direction="row" padding={4}>
      <View.Item grow>
        <Button
          ref={starcBtnRef}
          onClick={() => {
            isUS ? handleV2SignInClick() : handleSignInClick();
          }}
          href={!isUS ? routePaths.signIn : ''}
          variant={isUS ? 'pill' : 'pill-secondary'}
          attributes={{
            role: 'link',
            rel: 'nofollow',
            style: { height: '44px' },
            'aria-label': isUS
              ? labels.label_SignInorCreateAccount
              : labels.mf_homepage_header_signin,
          }}
          className={styles.signInV2Button}
        >
          <Text
            variant="display-5"
            weight="bold"
            attributes={{
              style: {
                lineHeight: '22px',
              },
            }}
            className={styles.starcOverrideTextVariantDisplay5}
          >
            {isUS ? (
              <Label label="label_SignInorCreateAccount" />
            ) : (
              <Label label="mf_homepage_header_signin" />
            )}
          </Text>
        </Button>
      </View.Item>
      <Hidden hide={isUS}>
        <View.Item gapBefore={3} grow>
          <Button
            onClick={handleCloseModal}
            variant="pill"
            attributes={{
              role: 'link',
              rel: 'nofollow',
              style: { height: '44px' },
            }}
            className={styles.signInV2Button}
            href={createAccountV2Route}
          >
            <Text
              variant="display-5"
              weight="bold"
              attributes={{
                style: {
                  lineHeight: '22px',
                },
              }}
              className={styles.starcOverrideTextVariantDisplay5}
            >
              <Label label="label_MyAccount_Menu_createAccount" />
            </Text>
          </Button>
        </View.Item>
      </Hidden>
    </View>
  );

  const showRewards =
    userType === SIGNED_IN_USER_WITH_REWARDS_USER_TYPE &&
    userAuthenticated &&
    isUS &&
    rewardsBalance !== '0.00' &&
    rewardsBalance !== '';

  const menuHeader = (
    <View padding={[2, 4]} justify="center" height="56px">
      <View direction="row" align="center" height="100%">
        <View.Item
          className={styles.salutation}
          grow
          attributes={{ style: { display: 'inline' }, 'data-testid': 'salutation-text' }}
        >
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {`${isUS ? '' : '¡'}${labels.label_MyAccount_Menu_greeting} ${formatName(
              userFirstName ?? ''
            )}!`}
          </div>
        </View.Item>
        <View.Item>
          <Text
            className={styles.rewardsBalance}
            variant="main-body"
            align="end"
            attributes={{ style: { fontWeight: '700' }, 'data-testid': 'rewards-balance-text' }}
          >
            {showRewards ? (
              <>
                <Label label="label_MyAccountMyProfile_landing_Rewards" />
                {`: $${rewardsBalance}`}
              </>
            ) : null}
          </Text>
        </View.Item>
      </View>
    </View>
  );

  const menuFooter = (
    <>
      <View direction="row" align="center" justify={'space-between'}>
        <View.Item
          className={cx(
            styles.listItemHoverState,
            styles.menuFooterOption,
            styles.menuFooterNeedHelp,
            { [styles.menuFooterNeedHelpMX]: !isUS }
          )}
        >
          <Actionable
            className={styles.needHelpBtn}
            onClick={() => {
              router.push(routePaths.contactUs);
              handleCloseModal();
            }}
            data-testid="contact-us-link"
            attributes={{
              id: 'at_contact_us_link_desktop',
              'data-testid': 'at_contact_us_link_desktop',
            }}
          >
            <Text
              variant="main-body"
              className={cx(styles.actionableNoPadding, styles.actionableOneLine)}
            >
              <Label label="label_MyAccount_Menu_needHelp" />
            </Text>
          </Actionable>
        </View.Item>
        <View.Item
          className={cx(
            styles.listItemHoverState,
            styles.menuFooterOption,
            styles.menuFooterSignOut
          )}
        >
          <Actionable
            className={styles.signOutBtn}
            onClick={handleCloseModalAndSignOut}
            attributes={{ 'data-testid': 'at_sign_out_link_desktop' }}
            data-testid="at_sign_out_link_desktop"
          >
            <Text variant="main-body" className={styles.actionableNoPadding}>
              <Label label="mf_homepage_header_signout" />
            </Text>
          </Actionable>
        </View.Item>
      </View>
      <MobileBrowserNavigationCompensation />
    </>
  );

  const menuItems = [
    {
      icon: NavigationHome,
      title: labels.label_AccountDashboard,
      description: labels.label_MyAccount_Menu_AccountDashboardDescription,
      href: routePaths.userMyAccount,
      boldTitle: true,
      signedInMenuItem: true,
    },
    {
      icon: OrderHistoryFilled,
      title: labels.label_OrderHistory,
      description: labels.label_MyAccount_Menu_OrderHistoryDescription,
      href: routePaths.userOrderHistory,
      signedInMenuItem: true,
    },
    {
      icon: AZRewardsIconFilled,
      title: labels.label_AutoZone_Rewards,
      description: labels.label_MyAccount_Menu_AutoZoneRewardsDescription,
      href: routePaths.rewardsLandingPage,
      usOnly: true,
      signedInMenuItem: true,
    },
    {
      icon: AZRewardsIconFilled,
      title: labels.labels_NavBar_earn_rewards,
      description: labels.label_MyAccount_Menu_EarnRewardsDescription,
      href: routePaths.rewardsLandingPage,
      usOnly: true,
      guestMenuItem: true,
    },
    {
      icon: Car,
      title: labels.label_MyVehicles,
      description: labels.label_MyAccount_Menu_MyVehiclesDescription,
      href: routePaths.userMyVehicle,
      signedInMenuItem: true,
    },
    {
      icon: Avatar,
      title: labels.label_MyAccountMyProfile_landing_Profile,
      description: labels.label_MyAccount_Menu_ProfileDescription,
      href: routePaths.userMyProfile,
      signedInMenuItem: true,
    },
    {
      icon: MyWarrantiesFilled,
      title: labels.label_MyWarranties_Title,
      description: labels.label_MyAccount_Menu_MyWarrantiesDescription,
      href: routePaths.myWarranties,
      usOnly: true,
      signedInMenuItem: true,
    },
    {
      icon: Located,
      title: labels.label_TrackOrder,
      description: labels.label_MyAccount_Menu_TrackOrderDescription,
      href: routePaths.trackOrder,
      hideMenuItem: !isUS && !isBopusMexicoStoreEnabled,
      signedInMenuItem: true,
      guestMenuItem: true,
    },
    {
      icon: RepairShop,
      title: labels.label_menu_FindaRepairShop,
      description: labels.label_MyAccount_Menu_FindARepairShopDescription,
      href: isUS ? routePaths.landingPageFindARepairShop : routePaths.landingPageFindARepairShopMX,
      guestMenuItem: true,
    },
  ];

  if (userAuthenticated) {
    return (
      <>
        {menuHeader}
        <hr className={styles.line} />
        <View padding={[2, 0]}>
          {menuItems
            .filter((item) => item.signedInMenuItem)
            .map((item) => (
              <MenuItem
                key={`${item.title}${item.href}`}
                icon={item.icon}
                title={item.title}
                boldTitle={item.boldTitle}
                description={item.description}
                href={item.href}
                onClick={handleCloseModal}
                usOnly={item.usOnly}
                hideMenuItem={item.hideMenuItem}
              />
            ))}
        </View>
        <hr className={styles.line} />
        {menuFooter}
        {showErr && (
          <div className={styles.error} data-test="show-err">
            <span>
              <Label label="label_ErrorSignOut" />
            </span>
            <br />
            <span>
              <Label label="label_PleaseTryAgain" />
            </span>
          </div>
        )}
      </>
    );
  } else {
    sessionStorage.setItem('DropDownRendered', 'true');
    return (
      <>
        {signInBlock}
        <hr className={styles.line} />
        <View padding={[2, 0]}>
          {menuItems
            .filter((item) => item.guestMenuItem)
            .map((item) => (
              <MenuItem
                key={`${item.title}${item.href}`}
                icon={item.icon}
                title={item.title}
                boldTitle={item.boldTitle}
                description={item.description}
                href={item.href}
                onClick={handleCloseModal}
                usOnly={item.usOnly}
                hideMenuItem={item.hideMenuItem}
              />
            ))}
        </View>
      </>
    );
  }
};

/**
 * Necessary to ensure entire menu is visible and/or scrollable using certain mobile devices
 * and bottom-oriented browser navigation toolbars (e.g. iPhone 8 Plus iOS 16.7)
 */
export function MobileBrowserNavigationCompensation() {
  return (
    <Hidden hide={{ s: false, m: true, l: true, xl: true }}>
      <View padding={10} />
    </Hidden>
  );
}
